import {useLocation} from 'react-router';
import {isTemplateBlacklistedForAdRefresh} from 'app/components/ads/adHelpers';
import {useSelector} from 'react-redux';
import {selectSsrRendered} from 'app/hooks/reduxCreateSelectorHooks';
import {useEffect, useState} from 'react';

const useAdRemount = () => {
  // Use 1) set component keys with `Date.now()` to force re-mount on location change by tabbar navigation
  // re-mount necessary to destroy ad slots correctly to avoid exceptions in queued GPT command
  // Use 2) generate unique ad id for infinite ads to avoid using same id again on location change
  const {pathname, search} = useLocation();
  const {blacklisted, template} = isTemplateBlacklistedForAdRefresh(pathname);
  const isHydrating = useSelector(selectSsrRendered);
  const [isInit, setIsInit] = useState(true);
  const isMatchPage = pathname.startsWith('/match/');
  const pathnameIsRegionMyMatchesPage =
    pathname.match(`\/(region|league|club|team)\/[a-zA-Z0-9-]+\/matches+.*$`) || pathname.match(`\/my\/matches+.*$`);

  let trigger = [pathname, null];
  // pathnameIsRegionMyMatchesPage: Remount if pathname is changed except for MatchCalendar, LeagueMatches, ClubMatches, TeamMatches and MyMatches. Use the search query instead
  // isMatchPage: Avoid update of key for subroute change on MatchPage
  // blacklisted: Avoid update of key for specific templates with different urls on same page (e.g. select_index)
  if (isMatchPage) {
    trigger = [];
  } else if (blacklisted) {
    trigger = [template];
  } else if (pathnameIsRegionMyMatchesPage) {
    trigger = [pathname, search];
  }
  // set init key for ssr and haydration to fix number for use as unique infinite ad id
  const [key, setKey] = useState(typeof window === 'undefined' || isHydrating ? 999 : Date.now());

  useEffect(() => {
    // Prevent key to be updated twice (on init state of useState and on hook registration by useEffect)
    if (!isInit) {
      setKey(Date.now());
    }
    setIsInit(false);
  }, trigger);

  return key;
};

export {useAdRemount};
