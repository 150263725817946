import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import ProgressBar from 'app/components/match/ProgressBar';

const Container = styled.div`
  position: relative;
  display: flex;
  max-width: ${SIZES['46']};
  width: ${SIZES['46']};
  justify-content: space-between;
`;

const Result = styled.div`
  border-radius: 4px;
  justify-content: center;
  max-width: 22px;
  width: 22px;
  height: ${SIZES['30']};
  text-align: center;
  background-color: ${props => props.color ?? COLORS.lighterGrey};
`;

const MatchResult = ({homeGoal, awayGoal, color, className, status, progress}) => {
  const standingHome = `${homeGoal ?? '-'}`;
  const standingAway = `${awayGoal ?? '-'}`;
  const showProgressBar = status === 'live' ? <ProgressBar progress={progress} small /> : null;

  return (
    <Container>
      <Result className={className} color={color}>
        <Typography variant={status === 'live' ? 'result-running' : 'result'}>{standingHome}</Typography>
      </Result>
      <Result className={className} color={color}>
        <Typography variant={status === 'live' ? 'result-running' : 'result'}>{standingAway}</Typography>
      </Result>
      {showProgressBar}
    </Container>
  );
};

export default MatchResult;
