import React from 'react';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {COLORS, SIZES} from '@fupa/fupa-uikit';

const Bar = styled(FlexContainer)`
  height: ${SIZES['2']};
  width: ${SIZES['72']};
  background-color: ${COLORS.lightGrey};
`;

const SmallBar = styled(Bar)`
  position: absolute;
  bottom: 0;
  &&& {
    width: ${SIZES['44']};
  }
`;

const Progress = styled.span`
  height: 100%;
  display: inherit;
  background-color: ${COLORS.fupaSecondary};
  width: ${props => props.progress}%;
  max-width: 100%;
`;

const ProgressBar = ({progress, small}) => {
  if (small) {
    return (
      <SmallBar>
        <Progress progress={progress} />
      </SmallBar>
    );
  } else {
    return (
      <Bar>
        <Progress progress={progress} />
      </Bar>
    );
  }
};

export default ProgressBar;
